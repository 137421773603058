//logOutDelay : total amount of time in minutes a user can be idle before forced logout
import { useHistory } from "react-router";

const useSessionTimer = (logOutDelay: number) => {
    //variables
    const userTimedOutStorageKey = "UserTimedOut";
    const userTimeoutId = "UserTimeoutId";
    const userTimeoutTimeStorageKey = "UserTimeoutTime";
    const navigate = useHistory();
    const logOutDelayTime = 1000 * 60 * logOutDelay;
    let logOutTime = 0;
    let logOutTimeout: NodeJS.Timeout;

    const keepAlive = function (e?: any) {
        if (e && e.target && (e.target.id === "login-button" || e.target.id === "logout-button")) {
            return;
        }

        window.sessionStorage.setItem(userTimedOutStorageKey, "false");

        clearTimeout(Number(window.sessionStorage.getItem(userTimeoutId)));        

        //reset logout time
        logOutTimeout = setTimeout(logOut, logOutDelayTime);
        logOutTime = Date.now() + logOutDelayTime;
        window.sessionStorage.setItem(userTimeoutTimeStorageKey, logOutTime.toString());
        window.sessionStorage.setItem(userTimeoutId, logOutTimeout.toString());
    };

    const logOut = async function () {
        window.sessionStorage.setItem(userTimedOutStorageKey, "true");
        window.sessionStorage.removeItem(userTimeoutTimeStorageKey);
        window.sessionStorage.removeItem(userTimeoutId);

        //destroy timeouts
        clearTimeout(logOutTimeout);

        //logout
        navigate.replace("/logout");
    };

    return {
        keepAlive
    };
};
export default useSessionTimer;
