import * as React from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Container } from "semantic-ui-react";
import { config } from "../config";

import PolicyContainer from "../_components/policy/PolicyContainer";
import PolicyList from "../_components/policy/PolicyList";
import DocumentUpload from "../_components/service/DocumentUpload";
import Freelook from "../_components/service/Freelook";
import Surrender from "../_components/service/Surrender";
import AdminFAQDetail from "../_components/admin/FAQDetail";
import AdminFAQList from "../_components/admin/FAQList";
import AdminFAQProducts from "../_components/admin/FAQDetailProducts";
import AdminFAQAdd from "../_components/admin/FAQAdd";
import AdminMarketingMessageList from "../_components/admin/MarketingMessageList";
import AdminMarketingMessageDetail from "../_components/admin/MarketingMessageDetail";
import AdminMarketingMessageAdd from "../_components/admin/MarketingMessageAdd";
import Impersonate from "../_components/admin/Impersonate";
import ImpersonateRedirectByPolicyKey from "../_components/admin/ImpersonateRedirectByPolicyKey";
import ImpersonateRedirectByPolicy from "../_components/admin/ImpersonateRedirectByPolicy";
import LandingPage from "../_components/LandingPage";
import Register from "../_components/auth/Register";
import Login from "../_components/auth/Login";
import Logout from "../_components/auth/Logout";
import ElectronicAuth from "../_components/service/ElectronicAuth";
import AdminMarketingMessageDetailStates from "../_components/admin/MarketingMessageDetailStates";
import FormDownload from "../_components/service/FormDownload";
import IVRResult from "../_components/admin/IVRResult";

import Home from "../_components/Home";
import TopNavbar from "../_components/shared/TopNavBar";
import Footer from "../_components/shared/Footer";
import { CPProvider } from "../_helpers/CPProvider";
import BeneficiaryChange from "../_components/service/BeneficiaryChange";

import Settings from "../_components/admin/Settings";
import SettingAdd from "../_components/admin/SettingAdd";
import SettingDetail from "../_components/admin/SettingDetail";

import Maintenance from "../_components/Maintenance";
import BankDraftDateChange from "../_components/service/BankDraftDateChange";
import RemotePayment from "../_components/service/RemotePayment";
import Forms from "../_components/service/Forms";
import Messages from "../_components/service/Messages";
import DocumentDelivery from "../_components/service/DocumentDelivery";
import DocumentDeliveryAuthorization from "../_components/service/DocumentDeliveryAuthorization";
import CompanyList from "../_components/service/CompanyList";
import AddNewBankDraft from "_components/service/AddNewBankDraft";
import ChangeBankRoutingAccount from "_components/service/ChangeBankRoutingAccount";
import ChangeBankDraftAmount from "_components/service/ChangeBankDraftAmount";
import OneTimePayment from "_components/service/OneTimePayment";
import BankDraftReview from "_components/service/BankDraftReview";
import useSessionTimer from "_helpers/useSessionTimer";

const oktaAuth = new OktaAuth(config.oidc);

// eslint-disable-next-line
const App = () => {
    const navigate = useHistory();
    const sessionTimer = useSessionTimer(Number(config.app.userTimeout));
    sessionTimer.keepAlive();

    //Store initial request
    if (window.location.pathname.includes("callback")) {
    } else {
        localStorage.setItem("appLoadUri", window.location.pathname + window.location.search);
    }

    const triggerLogin = async () => {
        await oktaAuth.signInWithRedirect();
    };

    const triggerLogout = async () => {
        navigate.replace("/logout");
    };

    // eslint-disable-next-line
    const restoreOriginalUri = async (_oktaAuth: any, originalUri: string) => {
        const originalUriCustom = localStorage.getItem("appLoadUri");
        //Testing a custom Uri for redirecting user and ignoring the function loaded one
        originalUri = originalUriCustom ?? "/";
        console.log("originalUriCustom=" + originalUriCustom);
        console.log("restoreOriginalUri = " + originalUri);
        navigate.replace(toRelativeUrl(originalUri || "/", window.location.origin));
    };

    const customAuthHandler = async () => {
        const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
        if (!previousAuthState || !previousAuthState.isAuthenticated) {
            await triggerLogin();
        } else {
            triggerLogout();
        }
    };

    React.useEffect(() => {
        window.addEventListener("click", sessionTimer.keepAlive);
        return () => {
            window.removeEventListener("click", sessionTimer.keepAlive);
        };
    }, []);

    return (
        <Security
            oktaAuth={oktaAuth}
            onAuthRequired={customAuthHandler}
            restoreOriginalUri={restoreOriginalUri}
        >
            <CPProvider>
                <TopNavbar />
                <Container text>
                    <Switch>
                        <Route path="/" exact component={Home} />
                        <Route path="/maint" exact component={Maintenance} />
                        <Route path="/login/callback" component={LoginCallback} />
                        <Route path="/register" component={Register} />
                        <Route path="/login" component={Login} />
                        <Route path="/logout" component={Logout} />
                        <SecureRoute path="/landingpage" component={LandingPage}></SecureRoute>
                        <SecureRoute path="/policies" component={PolicyList}></SecureRoute>
                        <SecureRoute
                            path="/policy-detail/:id"
                            component={PolicyContainer}
                        ></SecureRoute>
                        {/* Service/Requests */}
                        <SecureRoute path="/freelook/:id" component={Freelook}></SecureRoute>
                        <SecureRoute path="/surrender/:id" component={Surrender}></SecureRoute>
                        <SecureRoute
                            path="/electronicauth/:id"
                            component={ElectronicAuth}
                        ></SecureRoute>
                        <SecureRoute
                            path="/document-upload/:id"
                            component={DocumentUpload}
                        ></SecureRoute>
                        <SecureRoute path="/freelook/:id" component={Freelook}></SecureRoute>
                        <SecureRoute
                            path="/formdownload/:policyDWKey"
                            component={FormDownload}
                        ></SecureRoute>
                        <SecureRoute
                            path="/beneficiary-change/:id"
                            component={BeneficiaryChange}
                        ></SecureRoute>
                        <SecureRoute
                            path="/bankdraft-date-change/:id"
                            component={BankDraftDateChange}
                        ></SecureRoute>
                        <SecureRoute path="/payment/:id" component={RemotePayment}></SecureRoute>
                        <SecureRoute path="/download-forms/:id" component={Forms}></SecureRoute>
                        <SecureRoute
                            path="/document-delivery"
                            component={DocumentDelivery}
                        ></SecureRoute>
                        <SecureRoute path="/messages" component={Messages}></SecureRoute>
                        <SecureRoute path="/company-list" component={CompanyList}></SecureRoute>
                        <SecureRoute
                            path="/document-delivery-authorization"
                            component={DocumentDeliveryAuthorization}
                        ></SecureRoute>
                        <SecureRoute
                            path="/payments/add-new-bank-draft/:id"
                            component={AddNewBankDraft}
                        ></SecureRoute>
                        <SecureRoute
                            path="/payments/change-bank-routing-account/:id"
                            component={ChangeBankRoutingAccount}
                        ></SecureRoute>
                        <SecureRoute
                            path="/payments/change-bank-draft-amount/:id"
                            component={ChangeBankDraftAmount}
                        ></SecureRoute>
                        <SecureRoute
                            path="/payments/one-time-payment/:id"
                            component={OneTimePayment}
                        ></SecureRoute>
                        <SecureRoute
                            path="/payments/bank-draft-review/:id/:request"
                            component={BankDraftReview}
                        ></SecureRoute>
                        {/* Admin Related */}
                        <SecureRoute path="/ivrresult/:id" component={IVRResult}></SecureRoute>
                        <SecureRoute
                            path="/impersonateredirectbypolicykey/:id"
                            component={ImpersonateRedirectByPolicyKey}
                        ></SecureRoute>
                        <SecureRoute
                            path="/impersonateredirectbypolicy/:id"
                            component={ImpersonateRedirectByPolicy}
                        ></SecureRoute>
                        <SecureRoute path="/impersonate/:id" component={Impersonate}></SecureRoute>
                        <SecureRoute path="/admin/faqs" component={AdminFAQList}></SecureRoute>
                        <SecureRoute path="/admin/faq/:id" component={AdminFAQDetail}></SecureRoute>
                        <SecureRoute path="/admin/faqadd" component={AdminFAQAdd}></SecureRoute>
                        <SecureRoute
                            path="/admin/faq-products/:id"
                            component={AdminFAQProducts}
                        ></SecureRoute>
                        <SecureRoute
                            path="/admin/marketing-messages"
                            component={AdminMarketingMessageList}
                        ></SecureRoute>
                        <SecureRoute
                            path="/admin/marketing-message/:id"
                            component={AdminMarketingMessageDetail}
                        ></SecureRoute>
                        <SecureRoute
                            path="/admin/marketing-message-add"
                            component={AdminMarketingMessageAdd}
                        ></SecureRoute>
                        <SecureRoute
                            path="/admin/marketing-message-states/:id"
                            component={AdminMarketingMessageDetailStates}
                        ></SecureRoute>
                        <SecureRoute path="/admin/settings" component={Settings}></SecureRoute>
                        <SecureRoute
                            path="/admin/settingdetail/:id"
                            component={SettingDetail}
                        ></SecureRoute>
                        <SecureRoute path="/admin/settingadd" component={SettingAdd}></SecureRoute>
                    </Switch>
                </Container>
                <Footer />
            </CPProvider>
        </Security>
    );
};

export default App;
