import React, { useState, FC, useContext, useEffect } from "react";
import { config } from "../config";
import { CPContext } from "./CPContext";
import { UserClaims } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom";
import useFetch from "./useFetch";

export const CPProvider: FC = ({ children }) => {
    const { authState, oktaAuth } = useOktaAuth();
    const cpContext = useContext(CPContext);
    const { get } = useFetch();
    const [sessionId, setSessionId] = useState(cpContext.sessionId);
    const [impName, setImpName] = useState(cpContext.impName);
    const [impEmail, setImpEmail] = useState(cpContext.impEmail);
    const [isAdmin, setIsAdmin] = useState<boolean>(cpContext.isAdmin);
    const [isHO, setIsHO] = useState<boolean>(cpContext.isHO);
    const [hideForNonDev, setHideForNonDev] = useState<boolean>(cpContext.hideForNonDev);
    const [isCustomer, setIsCustomer] = useState<boolean>(cpContext.isCustomer);
    const [userInfo, setUserInfo] = useState<UserClaims>();
    const [customer, setCustomer] = useState<Customer>(cpContext.customer);
    const [featureFlags, setFeatureFlags] = useState<FeatureFlags>(cpContext.featureFlags);
    const [isMaint, setIsMaint] = useState<boolean>(cpContext.isMaint);
    const [messageCount, setMessageCount] = useState(0);
    
    const navigate = useHistory();

    useEffect(() => {
        const requestedUri = localStorage.getItem("appLoadUri");
        const getFeatureFlags = async () => {
            try {
                const result = await get<FeatureFlags>("/features", "");
                setFeatureFlags(result);
            } catch (error) {
                console.error(error);
            }
        };

        const getCustomerDetails = async () => {
            try {
                const result = await get<Customer>("/customer", "");
                setCustomer(result);
                setMessageCount(result.messageCount);
            } catch (error) {
                console.error(error);
            }
        };

        if (authState && authState.isAuthenticated) {
            oktaAuth
                .getUser()
                .then((info) => {
                    setUserInfo(info);
                })
                .catch((err) => {
                    console.error(err);
                });

            getFeatureFlags();
            getCustomerDetails();
            const accessToken = oktaAuth.getAccessToken() || "";
            // eslint-disable-next-line
            const decoded: any = jwt_decode(accessToken);
            if (decoded) {
                setIsCustomer(decoded.isCustomer);
                setIsAdmin(decoded.isAdmin);
                setIsHO(decoded.isHO);
                setSessionId(decoded.jti);
                setHideForNonDev(cpContext.hideForNonDev);
            }
        } else if (authState && !authState.isAuthenticated) {
            console.log("authState exists and user is NOT authenticated");
            if (requestedUri === "/register") {
                navigate.push(requestedUri);
            }
        } else {
            console.log("authState does NOT exist");
            if (requestedUri === "/register") {
                navigate.push(requestedUri);
            }
        }
        // eslint-disable-next-line
    }, [authState, oktaAuth]);

    const changeHideForNonDev = (_is: boolean) => {
        setHideForNonDev(_is);
    };
    const changeImpersonateName = (_name: string) => {
        setImpName(_name);
    };
    const changeImpersonateEmail = (_email: string) => {
        setImpEmail(_email);
    };
    const changeIsCustomer = (_is: boolean) => {
        setIsCustomer(_is);
    };
    const changeIsAdmin = (_is: boolean) => {
        setIsAdmin(_is);
    };
    const changeIsHO = (_is: boolean) => {
        setIsHO(_is);
    };
    const changeSessionId = (_id: string) => {
        setSessionId(_id);
    };
    const changeIsMaint = (_is: boolean) => {
        setIsMaint(_is);
    };
    const changeMessageCount = (_count: number) => {
        setMessageCount(_count);
    };

    return (
        <CPContext.Provider
            value={{
                impName,
                impEmail,
                isCustomer,
                isAdmin,
                isHO,
                hideForNonDev,
                userInfo,
                sessionId,
                isMaint,
                messageCount,
                featureFlags,
                customer,
                changeImpersonateName,
                changeImpersonateEmail,
                changeIsCustomer,
                changeIsAdmin,
                changeIsHO,
                changeSessionId,
                changeHideForNonDev,
                changeIsMaint,
                changeMessageCount
            }}
        >
            {children}
        </CPContext.Provider>
    );
};
